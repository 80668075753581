@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Raleway:wght@100&display=swap");

@layer base {
  body {
    @apply font-[Oswald];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(to right, #000, #000) !important;
}
